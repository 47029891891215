import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelOutlined, Info } from '@mui/icons-material';
import { TextField, Popover, Typography, Card, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { AsterikLabel } from '../Common/AsterikLabel';
import ConfirmationModal from '../Common/ConfirmationModal';
import { ACTIONS } from '../../common/constant';
import { Row } from 'reactstrap';
import { CancelBookingCreate } from '../../api/cancel_request';

export default function GenerateCancelRequest({ open, onClose, bedDetails, refresh }) {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const handleOpenConfimationModal = () => setIsConfirmModal(true);
    const handleCloseConfimationModal = () => setIsConfirmModal(false);
    const [isModal, setIsModal] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openInfo = Boolean(anchorEl);
    const [selectedOption, setSelectedOption] = useState('upi');

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseInfo = () => {
        setAnchorEl(null);
    };

    const formik = useFormik({
        initialValues: {
            selectedOption: "upi",
            note: '',
            upi_id: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            selectedOption: Yup.string().optional(),
            note: Yup.string().required('Please enter cancel reason'),
            upi_id: Yup.string().when('selectedOption', {
                is: (data) =>  data === 'upi',
                then: () => Yup.string().required('Please enter upi id').matches(/^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/, 'Please enter valid upi id')
            }),
            bank_detail: Yup.object().when('selectedOption', {
                is: (data) =>  data === 'bank',
                then: () => Yup.object().shape({
                account_holder_name: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .max(100, 'Too Long!')
                    .required('Please enter account holder name'),
                account_number: Yup.string()
                    .matches(/^\d{9,18}$/, 'Invalid account number')
                    .required('Please enter account number'),
                bank_name: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .max(100, 'Too Long!')
                    .required('Please enter bank name'),
                ifsc: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
                    .required('Please enter ifsc code'),
            }),
            })
        }),
    });

    const handleChange = (event) => {
        formik.setFieldValue('selectedOption', event.target.value)
        setSelectedOption(event.target.value);
    };

    const handleCancelBooking = async () => {
        try {
            const payload = {
                note: formik.values.note,
                tenant_id: bedDetails.tenant_id,
                bed_id: bedDetails.bed_id,
                payment_mode: formik.values.selectedOption,
                ...(formik.values.bank_detail && selectedOption === 'bank' ? {
                    bank_detail: {
                        ...(formik.values.bank_detail.account_holder_name && {
                            account_holder_name: formik.values.bank_detail.account_holder_name,
                        }),
                        ...(formik.values.bank_detail.account_number && {
                            account_number: formik.values.bank_detail.account_number,
                        }),
                        ...(formik.values.bank_detail.ifsc && {
                            ifsc: formik.values.bank_detail.ifsc,
                        }),
                        ...(formik.values.bank_detail.bank_name && {
                            bank_name: formik.values.bank_detail.bank_name,
                        }),
                    },
                } : {
                    upi_id: formik.values.upi_id
                }),
            }

            const response = await CancelBookingCreate(payload)
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh()
                onClose()
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    return (
        <React.Fragment>
            {isModal ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    disableScrollLock={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: { width: "560px", borderRadius: "10px", overflow: "hidden" },
                    }}
                    className='ticket_detail_modal'
                >
                    <DialogTitle className='ticket_detail_header d-flex justify-content-between'>
                        <div>
                            <small>Cancel Booking</small>
                        </div>
                        <CancelOutlined onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
                    </DialogTitle>
                    <DialogContent>
                        <div className='d-flex justify-content-center mt-2'>
                            <Card className="bedMatrixOptions" style={{ boxShadow: 'unset', marginLeft: '4px' }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        value={selectedOption}
                                        onClick={handleChange}
                                    >
                                        <FormControlLabel value="upi" control={<Radio color="default" />} label='UPI' style={{ color: 'black' }} />
                                        <FormControlLabel value="bank" control={<Radio color="default" />} label="BANK Account" style={{ color: 'black' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Card>
                        </div>
                        <div className='d-flex justify-content-center mb-3 mt-1'>
                            {formik.touched.leaving_date && formik.errors.leaving_date && (
                                <span className="text-danger">{formik.errors.leaving_date}</span>
                            )}
                        </div>
                        <div className='reason_box'>
                            <TextField
                                fullWidth
                                id="outlined-multiline-static txtAddress"
                                label={<div>Cancel Reasons <AsterikLabel /></div>}
                                placeholder='Enter your cancel reason'
                                name="note"
                                multiline
                                rows="3"
                                className="form-control"
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <div className='d-flex mb-3 mt-1'>
                                {formik.touched.note && formik.errors.note && (
                                    <span className="text-danger">{formik.errors.note}</span>
                                )}
                            </div>
                        </div>
                        {selectedOption === 'upi' ? (
                            <div className='reason_box'>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={<div>UPI ID <AsterikLabel /></div>}
                                    name="upi_id"
                                    value={formik.values?.upi_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                                <div className='d-flex mb-3 mt-1'>
                                    {formik.touched.upi_id && formik.errors.upi_id && (
                                        <span className="text-danger">{formik.errors.upi_id}</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <Row>
                                <div className='col-12 mb-1'>
                                    <div className='bankAccountDet'>
                                        <h5>Bank Account Details
                                            <Info onMouseEnter={handleClickInfo} className='ms-1' />
                                            <Popover
                                                className='popup_block'
                                                id={bedDetails?.bed_id}
                                                open={openInfo}
                                                anchorEl={anchorEl}
                                                onClose={handleCloseInfo}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Typography sx={{ p: 1 }} className='popContent'>
                                                    <p>Please provide your bank account  details to receive the caution money (after deduction of the pending payments if any)</p>
                                                </Typography>
                                            </Popover>
                                        </h5>
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-3'>
                                    <TextField
                                        size='small'
                                        className='form-control'
                                        type='text'
                                        name='bank_detail.account_holder_name'
                                        label={<div>Account Holder Name <AsterikLabel /></div>}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values?.bank_detail?.account_holder_name || ''}
                                    />
                                    <div className='d-flex mt-1'>
                                        {formik.touched?.bank_detail?.account_holder_name && formik.errors.bank_detail?.account_holder_name && (
                                            <span className="text-danger">{formik.errors.bank_detail?.account_holder_name}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-3'>
                                    <TextField
                                        size='small'
                                        className='form-control'
                                        type='text'
                                        name='bank_detail.account_number'
                                        label={<div>Account Number <AsterikLabel /></div>}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values?.bank_detail?.account_number || ''}
                                    />
                                    <div className='d-flex mt-1'>
                                        {formik.touched?.bank_detail?.account_number && formik.errors.bank_detail?.account_number && (
                                            <span className="text-danger">{formik.errors.bank_detail?.account_number}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-4'>
                                    <TextField
                                        size='small'
                                        className='form-control'
                                        type='text'
                                        name='bank_detail.ifsc'
                                        label={<div>IFSC <AsterikLabel /></div>}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values?.bank_detail?.ifsc || ''}
                                    />
                                    <div className='d-flex mt-1'>
                                        {formik.touched?.bank_detail?.ifsc && formik.errors.bank_detail?.ifsc && (
                                            <span className="text-danger">{formik.errors.bank_detail?.ifsc}</span>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-4'>
                                    <TextField
                                        size='small'
                                        className='form-control'
                                        type='text'
                                        name='bank_detail.bank_name'
                                        label={<div>Bank Name <AsterikLabel /></div>}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values?.bank_detail?.bank_name || ''}
                                    />
                                    <div className='d-flex mt-1'>
                                        {formik.touched?.bank_detail?.bank_name && formik.errors.bank_detail?.bank_name && (
                                            <span className="text-danger">{formik.errors.bank_detail?.bank_name}</span>
                                        )}
                                    </div>
                                </div>
                            </Row>
                        )}
                        <div className="gen_leave_add_btn text-center">
                            <Button className="yellow_gradient_btn pay_full_amt mt-3" disabled={!formik.isValid} onClick={() => {
                                setIsModal(false)
                                handleOpenConfimationModal()
                            }} style={formik.isValid ? { cursor: 'pointer' } : { cursor: 'not-allowed', backgroundColor: 'gray' }}> Save </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirmModal}
                    text="Please check carefully the bank account details before cancel the booking."
                    onCloseClick={() => {
                        setIsModal(true)
                        handleCloseConfimationModal()
                    }}
                        onAcceptClick={handleCancelBooking}
                />
            )}
        </React.Fragment>
    );
}
